import React from 'react'
import { Container} from 'reactstrap';
import Layout from '../components/Layout';
import { Heading1, Paragraph } from '../components/text';


function About() {
  const meta = [
    {
      name: 'description',
      content: 'The first Subway restaurant was opened in T&T in November 1992 in St James; 25 years later the brand is the second largest quick service restaurant in T&T with 44 restaurants in Trinidad and 3 in Tobago.'
    },
    {
      name: 'keywords',
      content: 'Subway, Trinidad, About Subway, Tobago'
    },
  ];

  return (
    <Layout title="About" meta={meta} className="py-md-5">
      <Container fluid className="px-md-6">
        <Heading1 sm={1.5} md={4} className="text-secondary">About Us</Heading1>
        <Paragraph sm={1} md={1.2}>Founded in 1965, SUBWAY® restaurants is the world's largest restaurant chain, with over 41,000 locations in more than 100 countries, offering a wide array of sandwiches, salads, wraps. The first Subway restaurant was opened in T&T in November 1992 in St James; 25 years later the brand is the second largest quick service restaurant in T&T with 43 restaurants in Trinidad and 3 in Tobago. Wherever you are in Trinidad & Tobago there is a SUBWAY offering great tasting, healthy eating near you.</Paragraph>
      </Container>
    </Layout>
  )
}
    
export default About;
